import 'styles/fancyLinks.css'

import { useContact } from 'hooks'
import { FC } from 'react'

const Contact: FC = () => {
  const contact = useContact()

  return (
    <div
      className={`fancyLinks`}
      dangerouslySetInnerHTML={{ __html: contact }}
    />
  )
}

export default Contact
