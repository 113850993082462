import SEO from '@bradgarropy/gatsby-plugin-seo'
import Contact from 'components/Pages/Contact'
import { FC } from 'react'

const ContactPage: FC = () => {
  return (
    <>
      <SEO
        title="Contact | Hacking the JS Interview"
        facebook={{
          image: 'https://hackingthejsinterview.com/logo.png'
        }}
        twitter={{
          image: 'https://hackingthejsinterview.com/logo.png',
          card: 'summary_large_image'
        }}
      />

      <Contact />
    </>
  )
}

export default ContactPage
