import { graphql, useStaticQuery } from 'gatsby'

const useAbout = (): string => {
  const query = graphql`
    {
      about: markdownRemark(
        fileAbsolutePath: { regex: "/content/pages/about/" }
      ) {
        html
      }
    }
  `

  const { about } = useStaticQuery(query)
  return about.html
}

export default useAbout
